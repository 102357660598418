<div class="new-page-feedback" fxLayout="row" fxLayoutAlign="center center">
  <span fxLayout="row" fxLayoutAlign="center center">
    {{ 'NEW' | translate }}
  </span>

  <button
    *ixIfNightly
    mat-button
    ixTest="new-page-feedback"
    fxLayout="row"
    fxLayoutAlign="center center"
    (click)="leaveFeedbackPressed()">
    {{ 'Leave Feedback' | translate }}
  </button>
</div>
