<h1 #errorTitle mat-dialog-title id="err-title" class="err-title">
  <ix-icon class="error-warning-icon" name="error"></ix-icon>
  {{ title | translate }}
</h1>
<div #errorMdContent id="err-md-content">
  <div #errorMessageWrapper id="err-message-wrapper"><span [innerHTML]="message"></span></div>
  <div
    *ngIf="backtrace"
    class="more-info"
    (click)="toggleOpen()"
  >
    <ix-icon *ngIf="isCloseMoreInfo" name="add_circle_outline"></ix-icon>
    <ix-icon *ngIf="!isCloseMoreInfo" name="remove_circle_outline"></ix-icon>
    <span>{{ 'More info...' | translate }}</span>
  </div>
  <div
    *ngIf="backtrace"
    #errorBtPanel
    id="err-bt-panel"
    class="backtrace-panel"
    [class.open]="!isCloseMoreInfo"
  >
    <div #errorBtText class="textarea scrolled-down" id="err-bt-text" readonly matInput>
      {{ 'Error' | translate }}: {{ backtrace }}
    </div>
  </div>
</div>
<button
  *ngIf="logs"
  mat-button
  class="mat-mdc-button mat-button mat-primary"
  ixTest="download-logs"
  (click)="downloadLogs()"
>
  {{ 'Download Logs' | translate }}
</button>
