<mat-nav-list role="list">
  <div *ngFor="let item of menuItems; let i = index">
    <ng-container *ngIf="!item.isVisible$ || (item.isVisible$ | async)">
      <!-- links and submenus -->
      <mat-list-item
        *ngIf="item.type !== MenuItemType.Separator"
        class="sidebar-list-item sidenav-link"
        role="listitem"
        routerLinkActive="highlighted"
        [id]="'nav-' + i"
        [class.has-submenu]="item.type === MenuItemType.SlideOut"
        [class.highlighted]="isHighlighted === item.state"
        (click)="updateHighlightedClass(item.state)"
      >
        <a
          *ngIf="item.type === MenuItemType.Link"
          matTooltipPosition="right"
          [ixTest]="getItemName(item)"
          [routerLink]="getRouterLink(item.state)"
          [name]="getItemName(item)"
          [matTooltip]="isSidenavCollapsed ? (item.tooltip | translate) : null"
          (click)="closeMenu()"
        >
          <ix-icon [name]="item.icon"></ix-icon>
          <span>{{ item.name | translate }}</span>
        </a>
        <a
          *ngIf="item.type === MenuItemType.SlideOut"
          tabindex="0"
          matTooltipPosition="right"
          [ixTest]="getItemName(item)"
          [name]="getItemName(item)"
          [matTooltip]="isSidenavCollapsed ? (item.tooltip | translate) : null"
          (click)="toggleMenu(item.state, item.sub)"
          (keyup.enter)="toggleMenu(item.state, item.sub)"
        >
          <ix-icon [name]="item.icon"></ix-icon>
          <span>{{ item.name | translate }}</span>
          <span fxFlex></span>
          <ix-icon class="menu-caret">keyboard_arrow_right</ix-icon>
        </a>

        <a
          *ngIf="item.type === MenuItemType.ExternalLink"
          target="_blank"
          matTooltipPosition="right"
          [ixTest]="getItemName(item)"
          [href]="item.state"
          [name]="getItemName(item)"
          [matTooltip]="isSidenavCollapsed ? (item.tooltip | translate) : null"
        >
          <ix-icon [name]="item.icon"></ix-icon>
          <span>{{ item.name | translate }}</span>
        </a>
      </mat-list-item>

      <div *ngIf="item.type === MenuItemType.Separator" class="mt-1 mb-1">
        <mat-divider></mat-divider>
        <span *ngIf="item.name" class="nav-section-title text-muted">
          {{ item.name | translate }}
        </span>
      </div>

      <a mat-button class="absorbs-focus" ixTest="focus-trap" [tabIndex]="-1">
        <!-- 'absorbs' the focus behavior -->
      </a>
    </ng-container>
  </div>
</mat-nav-list>
