<h1 matDialogTitle>{{ 'Connect to TrueCommand Cloud' | translate }}</h1>

<mat-dialog-content>
  <p [innerHtml]="helptext.signupDialog.content | translate"></p>
</mat-dialog-content>

<ix-form-actions mat-dialog-actions class="form-actions">
  <button mat-button type="button" ixTest="truecommand-signup" (click)="onSignup()">
    {{ 'Signup' | translate }}
  </button>

  <button mat-button type="button" ixTest="cancel-truecommand" [matDialogClose]="false">
    {{ 'Cancel' | translate }}
  </button>

  <button
    *ixRequiresRoles="requiredRoles"
    mat-button
    color="primary"
    ixTest="truecommand-connect"
    [matDialogClose]="true"
  >
    {{ 'Connect' | translate }}
  </button>
</ix-form-actions>
