<button
  *ngIf="tcStatus && tcStatus.status === TrueCommandStatus.Connecting"
  mat-icon-button
  id="tc-connecting"
  class="topbar-button-right"
  ixTest="truecommand-indicator"
  [matTooltip]="tooltips.tc_connect | translate"
  (click)="stopTrueCommandConnecting()"
>
  <ix-icon
    class="truecommand-button-connecting-animation"
    name="ix:logo_truecommand_white"
  ></ix-icon>
</button>

<button
  *ngIf="tcStatus && tcStatus.status !== TrueCommandStatus.Connecting"
  mat-icon-button
  id="tc-status"
  class="topbar-button-right truecommand-button"
  ixTest="truecommand-indicator"
  matBadgeSize="small"
  [matTooltip]="tooltips.tc_status | translate"
  [matBadge]="tcsStatusMatBadge"
  [ngClass]="[tcStatus.status]"
  (click)="handleClick()"
>
  <ix-icon name="ix:logo_truecommand_white"></ix-icon>
</button>
