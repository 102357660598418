<div mat-dialog-title>{{ 'TrueCommand Cloud Service' | translate }}</div>
<mat-divider></mat-divider>
<div mat-dialog-content class="tc-dialog-content">
  <div id="status">
    <ix-icon
      *ngIf="tc.status === TrueCommandStatus.Connected"
      class="status-connected"
      name="check_circle"
    ></ix-icon>
    <ix-icon
      *ngIf="tc.status === TrueCommandStatus.Failed"
      class="status-failed"
      name="cancel"
    ></ix-icon>
    <ix-icon
      *ngIf="tc.status === TrueCommandStatus.Disabled"
      class="status-disabled"
      name="pause_circle_filled"
    ></ix-icon>
    <span>{{ tc.status | translate }}</span>
  </div>
  <div *ngIf="tc.status_reason" id="error">
    {{ tc.status_reason | translate }}
  </div>
  <div
    *ngIf="tc.status === TrueCommandStatus.Connected && tc.remote_ip_address"
    id="tc-ip"
  >
    <ix-icon name="ix:logo_truecommand_blue" class="tc-icon"></ix-icon>
    <span>{{ tc.remote_ip_address }}</span>
  </div>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    color="primary"
    class="tc-action-button"
    ixTest="truecommand-settings"
    (click)="data.parent.handleUpdate()"
  >
    {{ 'Settings' | translate }}
  </button>
  <button
    mat-button
    color="primary"
    class="tc-action-button"
    ixTest="go-to-truecommand"
    [disabled]="!tc.remote_url || tc.status !== 'CONNECTED'"
    (click)="goToTrueCommand()"
  >
    {{ 'TrueCommand' | translate }}
  </button>
</div>
