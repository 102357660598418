<div *ngIf="(loggedInUser$ | async) as user" class="user-menu-wrapper">
  <button
    name="Settings"
    mat-icon-button
    class="topbar-button-right"
    ixTest="user-menu"
    [matTooltip]="tooltips.settings | translate"
    [matMenuTriggerFor]="userMenu"
  >
    <span
      *ngIf="user?.pw_name"
      class="username"
      [matTooltip]="user.pw_name"
      [matMenuTriggerFor]="userMenu"
    >
      {{ user.pw_name }}
    </span>

    <ix-icon name="account_circle"></ix-icon>
  </button>

  <mat-menu #userMenu="matMenu">
    <button
      *ngIf="user?.account_attributes.includes(AccountAttribute.Local)"
      name="settings-change-password"
      mat-menu-item
      ixTest="change-password"
      (click)="openChangePasswordDialog()"
    >
      <ix-icon name="dialpad"></ix-icon>
      {{ 'Change Password' | translate }}
    </button>

    <button
      name="settings-2fa"
      mat-menu-item
      ixTest="2fa"
      (click)="onTwoFactorAuth()"
    >
      <ix-icon name="ix:two_factor_auth"></ix-icon>
      {{ 'Two-Factor Authentication' | translate }}
    </button>

    <a name="settings-api" ixTest="api-keys" mat-menu-item [routerLink]="['/apikeys']">
      <ix-icon name="laptop"></ix-icon>
      {{ 'API Keys' | translate }}
    </a>

    <a
      name="settings-guide"
      mat-menu-item
      href="https://www.truenas.com/docs/"
      target="_blank"
      ixTest="guide"
    >
      <ix-icon name="library_books"></ix-icon>
      {{ 'Guide' | translate }}
    </a>

    <button name="settings-about" mat-menu-item ixTest="about" (click)="onShowAbout()">
      <ix-icon name="info_outline"></ix-icon>
      {{ 'About' | translate }}
    </button>
  </mat-menu>
</div>
