<mat-slide-toggle
  color="primary"
  [checked]="value"
  [required]="required"
  [disabled]="isDisabled"
  [ixTest]="controlDirective.name"
  (change)="onSlideToggleChanged($event)"
  (blur)="onTouch()"
>
  {{ label }}
  <span *ngIf="required" class="required">*</span>
</mat-slide-toggle>

<ix-tooltip
  *ngIf="tooltip"
  class="tooltip"
  [header]="label"
  [message]="tooltip"
></ix-tooltip>

<ix-errors [control]="controlDirective.control" [label]="label"></ix-errors>
