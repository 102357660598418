<button
  mat-button
  type="button"
  class="oauth-button"
  [ixTest]="testId"
  [disabled]="disabled"
  [color]="isLoggedIn ? 'accent' : 'primary'"
  (click)="onOauthClicked()"
>
  {{ buttonText | translate }}
</button>
