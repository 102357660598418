<div class="full-width container has-tooltip">
  <ix-label
    *ngIf="label || tooltip"
    [label]="label"
    [tooltip]="tooltip"
    [required]="required"
    [ixTestOverride]="controlDirective.name"
  ></ix-label>

  <div class="input-container" [class.disabled]="isDisabled" [class.readonly]="readonly">
    <textarea
      matInput
      [rows]="rows"
      [required]="required"
      [disabled]="isDisabled"
      [readonly]="readonly"
      [placeholder]="placeholder"
      [ixTest]="controlDirective.name"
      [attr.aria-label]="label"
      [(ngModel)]="value"
      (ngModelChange)="onChange($event)"
      (blur)="onTouch()"
    ></textarea>
  </div>
  <ix-errors [control]="controlDirective.control" [label]="label"></ix-errors>

  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</div>
