<ix-icon
  class="alert-icon"
  [name]="icon"
  [color]="alertLevelColor"
  [matTooltip]="iconTooltip"
></ix-icon>

<div class="alert-body">
  <h3 *ngIf="!alert.dismissed" [class]="['alert-level', alertLevelColor]">
    {{ alertLevelLabel }}
  </h3>
  <h4 class="alert-message" [innerHTML]="alert.formatted"></h4>
  <div *ngIf="isHaLicensed" class="alert-node">{{ alert.node }}</div>
  <div class="alert-time">
    {{ alert.datetime.$date | formatDateTime }}
    <ng-container *ngIf="timezone$ | async as timezone">
      ({{ timezone }})
    </ng-container>
  </div>

  <a
    *ngIf="alert.dismissed; else reopenLink"
    tabindex="0"
    role="button"
    class="action-link"
    [ixTest]="[alert.key, 'reopen']"
    (click)="onReopen()"
  >
    {{ 'Re-Open' | translate }}
  </a>
  <ng-template #reopenLink>
    <a
      tabindex="0"
      role="button"
      class="action-link"
      [ixTest]="[alert.key, 'dismiss']"
      (click)="onDismiss()"
    >
      {{ 'Dismiss' | translate }}
    </a>
  </ng-template>
</div>
