import { Enclosure } from 'app/interfaces/enclosure.interface';
import { MockEnclosure } from './mock-enclosure-template';

export class MockEs12 extends MockEnclosure {
  readonly totalSlotsFront: number = 12;
  readonly totalSlotsRear: number = 0;
  readonly totalSlotsInternal: number = 0;

  data = {
    id: '500e0eca0651517f',
    name: 'CELESTIC X2012-MT 0443',
    model: 'ES12',
    controller: false,
    elements: [
      {
        name: 'Array Device Slot',
        descriptor: '',
        header: [
          'Descriptor',
          'Status',
          'Value',
          'Device',
        ],
        elements: [],
        has_slot_status: true,
      },
      {
        name: 'Cooling',
        descriptor: '',
        header: [
          'Descriptor',
          'Status',
          'Value',
        ],
        elements: [
          {
            slot: 1,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '5360 RPM',
            },
            name: 'Cooling',
            descriptor: '',
            status: 'OK',
            value: '5360 RPM',
            value_raw: '0x1021801',
          },
          {
            slot: 2,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '5310 RPM',
            },
            name: 'Cooling',
            descriptor: '',
            status: 'OK',
            value: '5310 RPM',
            value_raw: '0x1021301',
          },
          {
            slot: 3,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '6200 RPM',
            },
            name: 'Cooling',
            descriptor: '',
            status: 'OK',
            value: '6200 RPM',
            value_raw: '0x1026c01',
          },
          {
            slot: 4,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '4600 RPM',
            },
            name: 'Cooling',
            descriptor: '',
            status: 'OK',
            value: '4600 RPM',
            value_raw: '0x101cc01',
          },
          {
            slot: 5,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '6200 RPM',
            },
            name: 'Cooling',
            descriptor: '',
            status: 'OK',
            value: '6200 RPM',
            value_raw: '0x1026c01',
          },
          {
            slot: 6,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '4600 RPM',
            },
            name: 'Cooling',
            descriptor: '',
            status: 'OK',
            value: '4600 RPM',
            value_raw: '0x101cc01',
          },
          {
            slot: 7,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '6100 RPM',
            },
            name: 'Cooling',
            descriptor: '',
            status: 'OK',
            value: '6100 RPM',
            value_raw: '0x1026201',
          },
          {
            slot: 8,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '4500 RPM',
            },
            name: 'Cooling',
            descriptor: '',
            status: 'OK',
            value: '4500 RPM',
            value_raw: '0x101c201',
          },
          {
            slot: 9,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '6000 RPM',
            },
            name: 'Cooling',
            descriptor: '',
            status: 'OK',
            value: '6000 RPM',
            value_raw: '0x1025801',
          },
          {
            slot: 10,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '4600 RPM',
            },
            name: 'Cooling',
            descriptor: '',
            status: 'OK',
            value: '4600 RPM',
            value_raw: '0x101cc01',
          },
          {
            slot: 11,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '6100 RPM',
            },
            name: 'Cooling',
            descriptor: '',
            status: 'OK',
            value: '6100 RPM',
            value_raw: '0x1026201',
          },
          {
            slot: 12,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '4600 RPM',
            },
            name: 'Cooling',
            descriptor: '',
            status: 'OK',
            value: '4600 RPM',
            value_raw: '0x101cc01',
          },
          {
            slot: 13,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '6100 RPM',
            },
            name: 'Cooling',
            descriptor: '',
            status: 'OK',
            value: '6100 RPM',
            value_raw: '0x1026201',
          },
          {
            slot: 14,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '4500 RPM',
            },
            name: 'Cooling',
            descriptor: '',
            status: 'OK',
            value: '4500 RPM',
            value_raw: '0x101c201',
          },
        ],
        has_slot_status: false,
      },
      {
        name: 'Enclosure',
        descriptor: '',
        header: [
          'Descriptor',
          'Status',
          'Value',
        ],
        elements: [
          {
            slot: 1,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: 'None',
            },
            name: 'Enclosure',
            descriptor: '',
            status: 'OK',
            value: 'None',
            value_raw: '0x1000000',
          },
        ],
        has_slot_status: false,
      },
      {
        name: 'Enclosure Services Controller Electronics',
        descriptor: '',
        header: [
          'Descriptor',
          'Status',
          'Value',
        ],
        elements: [
          {
            slot: 1,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: 384,
            },
            name: 'Enclosure Services Controller Electronics',
            descriptor: '',
            status: 'OK',
            value: 384,
            value_raw: '0x1000180',
          },
          {
            slot: 2,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: 384,
            },
            name: 'Enclosure Services Controller Electronics',
            descriptor: '',
            status: 'OK',
            value: 384,
            value_raw: '0x1000180',
          },
        ],
        has_slot_status: false,
      },
      {
        name: 'Power Supply',
        descriptor: '',
        header: [
          'Descriptor',
          'Status',
          'Value',
        ],
        elements: [
          {
            slot: 1,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: 'None',
            },
            name: 'Power Supply',
            descriptor: '',
            status: 'OK',
            value: 'None',
            value_raw: '0x1000020',
          },
          {
            slot: 2,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: 'None',
            },
            name: 'Power Supply',
            descriptor: '',
            status: 'OK',
            value: 'None',
            value_raw: '0x1000020',
          },
        ],
        has_slot_status: false,
      },
      {
        name: 'SAS Connector',
        descriptor: '',
        header: [
          'Descriptor',
          'Status',
          'Value',
        ],
        elements: [
          {
            slot: 1,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            },
            name: 'SAS Connector',
            descriptor: '',
            status: 'OK',
            value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            value_raw: '0x105ff00',
          },
          {
            slot: 2,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            },
            name: 'SAS Connector',
            descriptor: '',
            status: 'OK',
            value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            value_raw: '0x105ff00',
          },
          {
            slot: 3,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            },
            name: 'SAS Connector',
            descriptor: '',
            status: 'OK',
            value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            value_raw: '0x105ff00',
          },
          {
            slot: 4,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            },
            name: 'SAS Connector',
            descriptor: '',
            status: 'OK',
            value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            value_raw: '0x105ff00',
          },
          {
            slot: 5,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            },
            name: 'SAS Connector',
            descriptor: '',
            status: 'OK',
            value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            value_raw: '0x105ff00',
          },
          {
            slot: 6,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            },
            name: 'SAS Connector',
            descriptor: '',
            status: 'OK',
            value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            value_raw: '0x105ff00',
          },
        ],
        has_slot_status: false,
      },
      {
        name: 'SAS Expander',
        descriptor: '',
        header: [
          'Descriptor',
          'Status',
          'Value',
        ],
        elements: [
          {
            slot: 1,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: 'None',
            },
            name: 'SAS Expander',
            descriptor: '',
            status: 'OK',
            value: 'None',
            value_raw: '0x1000000',
          },
          {
            slot: 2,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: 'None',
            },
            name: 'SAS Expander',
            descriptor: '',
            status: 'OK',
            value: 'None',
            value_raw: '0x1000000',
          },
        ],
        has_slot_status: false,
      },
      {
        name: 'Temperature Sensor',
        descriptor: '',
        header: [
          'Descriptor',
          'Status',
          'Value',
        ],
        elements: [
          {
            slot: 1,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '21C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '21C',
            value_raw: '0x1002900',
          },
          {
            slot: 2,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '21C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '21C',
            value_raw: '0x1002900',
          },
          {
            slot: 3,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '27C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '27C',
            value_raw: '0x1002f00',
          },
          {
            slot: 4,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '21C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '21C',
            value_raw: '0x1002900',
          },
          {
            slot: 5,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '21C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '21C',
            value_raw: '0x1002900',
          },
          {
            slot: 6,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '27C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '27C',
            value_raw: '0x1002f00',
          },
          {
            slot: 7,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '45C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '45C',
            value_raw: '0x1004100',
          },
          {
            slot: 8,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '27C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '27C',
            value_raw: '0x1002f00',
          },
          {
            slot: 9,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '23C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '23C',
            value_raw: '0x1002b00',
          },
          {
            slot: 10,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '45C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '45C',
            value_raw: '0x1004100',
          },
          {
            slot: 11,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '24C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '24C',
            value_raw: '0x1002c00',
          },
          {
            slot: 12,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '22C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '22C',
            value_raw: '0x1002a00',
          },
        ],
        has_slot_status: false,
      },
      {
        name: 'Voltage Sensor',
        descriptor: '',
        header: [
          'Descriptor',
          'Status',
          'Value',
        ],
        elements: [
          {
            slot: 1,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '0.93V',
            },
            name: 'Voltage Sensor',
            descriptor: '',
            status: 'OK',
            value: '0.93V',
            value_raw: '0x100005d',
          },
          {
            slot: 2,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '1.0V',
            },
            name: 'Voltage Sensor',
            descriptor: '',
            status: 'OK',
            value: '1.0V',
            value_raw: '0x1000064',
          },
          {
            slot: 3,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '1.8V',
            },
            name: 'Voltage Sensor',
            descriptor: '',
            status: 'OK',
            value: '1.8V',
            value_raw: '0x10000b4',
          },
          {
            slot: 4,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '3.31V',
            },
            name: 'Voltage Sensor',
            descriptor: '',
            status: 'OK',
            value: '3.31V',
            value_raw: '0x100014b',
          },
          {
            slot: 5,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '0.92V',
            },
            name: 'Voltage Sensor',
            descriptor: '',
            status: 'OK',
            value: '0.92V',
            value_raw: '0x100005c',
          },
          {
            slot: 6,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '0.99V',
            },
            name: 'Voltage Sensor',
            descriptor: '',
            status: 'OK',
            value: '0.99V',
            value_raw: '0x1000063',
          },
          {
            slot: 7,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '1.77V',
            },
            name: 'Voltage Sensor',
            descriptor: '',
            status: 'OK',
            value: '1.77V',
            value_raw: '0x10000b1',
          },
          {
            slot: 8,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '3.28V',
            },
            name: 'Voltage Sensor',
            descriptor: '',
            status: 'OK',
            value: '3.28V',
            value_raw: '0x1000148',
          },
        ],
        has_slot_status: false,
      },
    ],
    number: 1,
    label: 'CELESTIC X2012-MT 0443',
  } as Enclosure;

  constructor(number: number) {
    super(number);
    this.enclosureNumber = number;
    this.enclosureInit();
  }
}
