<h1 mat-dialog-title>{{ options.title | translate }}</h1>
<div mat-dialog-content id="confirm-msg">
  <div class="message-content">
    <span [innerHTML]="options.message | translate"></span>
  </div>

  <span *ngIf="options.secondaryCheckbox">
    <mat-checkbox
      class="confirm-checkbox secondary-checkbox"
      ixTest="secondary-confirmation"
      [(ngModel)]="isSecondaryCheckboxChecked"
    >
      <span id="confirm-label">{{ options.secondaryCheckboxText | translate }}</span>
    </mat-checkbox>
  </span>
</div>

<ix-form-actions mat-dialog-actions class="form-actions">
  <mat-checkbox
    *ngIf="!options.hideCheckbox"
    color="primary"
    name="confirm_checkbox"
    class="confirm-checkbox"
    ixTest="confirm"
    (change)="toggleSubmit($event)"
  >
    {{ options.confirmationCheckboxText | translate }}
  </mat-checkbox>
  <span fxFlex></span>
  <button
    *ngIf="!options.hideCancel"
    mat-button
    name="cancel_button"
    class="mat-mdc-button mat-button mat-accent cancel-button"
    ixTest="dialog-cancel"
    (click)="onCancel()"
  >
    {{ options.cancelText | translate }}
  </button>
  <button
    mat-button
    name="ok_button"
    class="mat-mdc-button mat-button mat-primary submit-button"
    ixTest="dialog-confirm"
    [disabled]="isDisabled()"
    [color]="options.buttonColor || 'primary'"
    (click)="onSubmit()"
  >
    {{ options.buttonText | translate }}
  </button>
</ix-form-actions>
