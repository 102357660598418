export enum DiskStandby {
  AlwaysOn = 'ALWAYS ON',
  Minutes5 = '5',
  Minutes10 = '10',
  Minutes20 = '20',
  Minutes30 = '30',
  Minutes60 = '60',
  Minutes120 = '120',
  Minutes180 = '180',
  Minutes240 = '240',
  Minutes300 = '300',
  Minutes330 = '330',
}
