import { Enclosure } from 'app/interfaces/enclosure.interface';
import { MockEnclosure } from './mock-enclosure-template';

export class MockEs60G2 extends MockEnclosure {
  readonly totalSlotsFront: number = 60;
  readonly totalSlotsRear: number = 0;
  readonly totalSlotsInternal: number = 0;

  data = {
    id: '5000ccab05114080',
    name: 'HGST H4060-J 3010',
    model: 'ES60G2',
    controller: false,
    elements: [
      {
        name: 'Array Device Slot',
        descriptor: '',
        header: [
          'Descriptor',
          'Status',
          'Value',
          'Device',
        ],
        elements: [],
        has_slot_status: true,
      },
      {
        name: 'Cooling',
        descriptor: '',
        header: [
          'Descriptor',
          'Status',
          'Value',
        ],
        elements: [
          {
            slot: 1,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '4040 RPM',
            },
            name: 'Cooling',
            descriptor: '',
            status: 'OK',
            value: '4040 RPM',
            value_raw: '0x10194a3',
          },
          {
            slot: 2,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '4030 RPM',
            },
            name: 'Cooling',
            descriptor: '',
            status: 'OK',
            value: '4030 RPM',
            value_raw: '0x10193a3',
          },
          {
            slot: 3,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '4080 RPM',
            },
            name: 'Cooling',
            descriptor: '',
            status: 'OK',
            value: '4080 RPM',
            value_raw: '0x10198a3',
          },
          {
            slot: 4,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '4030 RPM',
            },
            name: 'Cooling',
            descriptor: '',
            status: 'OK',
            value: '4030 RPM',
            value_raw: '0x10193a3',
          },
          {
            slot: 5,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '19350 RPM',
            },
            name: 'Cooling',
            descriptor: '',
            status: 'OK',
            value: '19350 RPM',
            value_raw: '0x1078fa6',
          },
          {
            slot: 6,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '16790 RPM',
            },
            name: 'Cooling',
            descriptor: '',
            status: 'OK',
            value: '16790 RPM',
            value_raw: '0x1068fa6',
          },
          {
            slot: 7,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '20470 RPM',
            },
            name: 'Cooling',
            descriptor: '',
            status: 'OK',
            value: '20470 RPM',
            value_raw: '0x107ffa6',
          },
          {
            slot: 8,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '20470 RPM',
            },
            name: 'Cooling',
            descriptor: '',
            status: 'OK',
            value: '20470 RPM',
            value_raw: '0x107ffa6',
          },
        ],
        has_slot_status: false,
      },
      {
        name: 'Current Sensor',
        descriptor: '',
        header: [
          'Descriptor',
          'Status',
          'Value',
        ],
        elements: [
          {
            slot: 1,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '2.21A',
            },
            name: 'Current Sensor',
            descriptor: '',
            status: 'OK',
            value: '2.21A',
            value_raw: '0x10000dd',
          },
          {
            slot: 2,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '32.44A',
            },
            name: 'Current Sensor',
            descriptor: '',
            status: 'OK',
            value: '32.44A',
            value_raw: '0x1000cac',
          },
          {
            slot: 3,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '2.21A',
            },
            name: 'Current Sensor',
            descriptor: '',
            status: 'OK',
            value: '2.21A',
            value_raw: '0x10000dd',
          },
          {
            slot: 4,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '32.88A',
            },
            name: 'Current Sensor',
            descriptor: '',
            status: 'OK',
            value: '32.88A',
            value_raw: '0x1000cd8',
          },
          {
            slot: 5,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '15.68A',
            },
            name: 'Current Sensor',
            descriptor: '',
            status: 'OK',
            value: '15.68A',
            value_raw: '0x1000620',
          },
          {
            slot: 6,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '23.0A',
            },
            name: 'Current Sensor',
            descriptor: '',
            status: 'OK',
            value: '23.0A',
            value_raw: '0x10008fc',
          },
          {
            slot: 7,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '13.58A',
            },
            name: 'Current Sensor',
            descriptor: '',
            status: 'OK',
            value: '13.58A',
            value_raw: '0x100054e',
          },
          {
            slot: 8,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '19.0A',
            },
            name: 'Current Sensor',
            descriptor: '',
            status: 'OK',
            value: '19.0A',
            value_raw: '0x100076c',
          },
        ],
        has_slot_status: false,
      },
      {
        name: 'Door',
        descriptor: '',
        header: [
          'Descriptor',
          'Status',
          'Value',
        ],
        elements: [
          {
            slot: 1,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: 0,
            },
            name: 'Door',
            descriptor: '',
            status: 'OK',
            value: 0,
            value_raw: '0x1000000',
          },
        ],
        has_slot_status: false,
      },
      {
        name: 'Enclosure',
        descriptor: '',
        header: [
          'Descriptor',
          'Status',
          'Value',
        ],
        elements: [
          {
            slot: 1,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: 'None',
            },
            name: 'Enclosure',
            descriptor: '',
            status: 'OK',
            value: 'None',
            value_raw: '0x1000000',
          },
        ],
        has_slot_status: false,
      },
      {
        name: 'Enclosure Services Controller Electronics',
        descriptor: '',
        header: [
          'Descriptor',
          'Status',
          'Value',
        ],
        elements: [
          {
            slot: 1,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: 384,
            },
            name: 'Enclosure Services Controller Electronics',
            descriptor: '',
            status: 'OK',
            value: 384,
            value_raw: '0x1100180',
          },
          {
            slot: 2,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: 128,
            },
            name: 'Enclosure Services Controller Electronics',
            descriptor: '',
            status: 'OK',
            value: 128,
            value_raw: '0x1100080',
          },
        ],
        has_slot_status: false,
      },
      {
        name: 'Power Supply',
        descriptor: '',
        header: [
          'Descriptor',
          'Status',
          'Value',
        ],
        elements: [
          {
            slot: 1,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: 'None',
            },
            name: 'Power Supply',
            descriptor: '',
            status: 'OK',
            value: 'None',
            value_raw: '0x10000a0',
          },
          {
            slot: 2,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: 'None',
            },
            name: 'Power Supply',
            descriptor: '',
            status: 'OK',
            value: 'None',
            value_raw: '0x10000a0',
          },
        ],
        has_slot_status: false,
      },
      {
        name: 'SAS Connector',
        descriptor: '',
        header: [
          'Descriptor',
          'Status',
          'Value',
        ],
        elements: [
          {
            slot: 1,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            },
            name: 'SAS Connector',
            descriptor: '',
            status: 'OK',
            value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            value_raw: '0x105ff80',
          },
          {
            slot: 2,
            data: {
              Descriptor: '',
              Status: 'Not installed',
              Value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            },
            name: 'SAS Connector',
            descriptor: '',
            status: 'Not installed',
            value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            value_raw: '0x505ff00',
          },
          {
            slot: 3,
            data: {
              Descriptor: '',
              Status: 'Not installed',
              Value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            },
            name: 'SAS Connector',
            descriptor: '',
            status: 'Not installed',
            value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            value_raw: '0x505ff00',
          },
          {
            slot: 4,
            data: {
              Descriptor: '',
              Status: 'Not installed',
              Value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            },
            name: 'SAS Connector',
            descriptor: '',
            status: 'Not installed',
            value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            value_raw: '0x505ff00',
          },
          {
            slot: 5,
            data: {
              Descriptor: '',
              Status: 'Not installed',
              Value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            },
            name: 'SAS Connector',
            descriptor: '',
            status: 'Not installed',
            value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            value_raw: '0x505ff00',
          },
          {
            slot: 6,
            data: {
              Descriptor: '',
              Status: 'Not installed',
              Value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            },
            name: 'SAS Connector',
            descriptor: '',
            status: 'Not installed',
            value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            value_raw: '0x505ff00',
          },
          {
            slot: 7,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            },
            name: 'SAS Connector',
            descriptor: '',
            status: 'OK',
            value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            value_raw: '0x105ff80',
          },
          {
            slot: 8,
            data: {
              Descriptor: '',
              Status: 'Not installed',
              Value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            },
            name: 'SAS Connector',
            descriptor: '',
            status: 'Not installed',
            value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            value_raw: '0x505ff00',
          },
          {
            slot: 9,
            data: {
              Descriptor: '',
              Status: 'Not installed',
              Value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            },
            name: 'SAS Connector',
            descriptor: '',
            status: 'Not installed',
            value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            value_raw: '0x505ff00',
          },
          {
            slot: 10,
            data: {
              Descriptor: '',
              Status: 'Not installed',
              Value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            },
            name: 'SAS Connector',
            descriptor: '',
            status: 'Not installed',
            value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            value_raw: '0x505ff00',
          },
          {
            slot: 11,
            data: {
              Descriptor: '',
              Status: 'Not installed',
              Value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            },
            name: 'SAS Connector',
            descriptor: '',
            status: 'Not installed',
            value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            value_raw: '0x505ff00',
          },
          {
            slot: 12,
            data: {
              Descriptor: '',
              Status: 'Not installed',
              Value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            },
            name: 'SAS Connector',
            descriptor: '',
            status: 'Not installed',
            value: 'Mini SAS HD 4x receptacle (SFF-8644) [max 4 phys]',
            value_raw: '0x505ff00',
          },
        ],
        has_slot_status: false,
      },
      {
        name: 'SAS Expander',
        descriptor: '',
        header: [
          'Descriptor',
          'Status',
          'Value',
        ],
        elements: [
          {
            slot: 1,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: 'None',
            },
            name: 'SAS Expander',
            descriptor: '',
            status: 'OK',
            value: 'None',
            value_raw: '0x1000000',
          },
          {
            slot: 2,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: 'None',
            },
            name: 'SAS Expander',
            descriptor: '',
            status: 'OK',
            value: 'None',
            value_raw: '0x1000000',
          },
          {
            slot: 3,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: 'None',
            },
            name: 'SAS Expander',
            descriptor: '',
            status: 'OK',
            value: 'None',
            value_raw: '0x1000000',
          },
          {
            slot: 4,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: 'None',
            },
            name: 'SAS Expander',
            descriptor: '',
            status: 'OK',
            value: 'None',
            value_raw: '0x1000000',
          },
          {
            slot: 5,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: 'None',
            },
            name: 'SAS Expander',
            descriptor: '',
            status: 'OK',
            value: 'None',
            value_raw: '0x1000000',
          },
          {
            slot: 6,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: 'None',
            },
            name: 'SAS Expander',
            descriptor: '',
            status: 'OK',
            value: 'None',
            value_raw: '0x1000000',
          },
        ],
        has_slot_status: false,
      },
      {
        name: 'Temperature Sensor',
        descriptor: '',
        header: [
          'Descriptor',
          'Status',
          'Value',
        ],
        elements: [
          {
            slot: 1,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '26C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '26C',
            value_raw: '0x1002e00',
          },
          {
            slot: 2,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '26C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '26C',
            value_raw: '0x1002e00',
          },
          {
            slot: 3,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '26C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '26C',
            value_raw: '0x1002e00',
          },
          {
            slot: 4,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '26C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '26C',
            value_raw: '0x1002e00',
          },
          {
            slot: 5,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '26C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '26C',
            value_raw: '0x1002e00',
          },
          {
            slot: 6,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '26C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '26C',
            value_raw: '0x1002e00',
          },
          {
            slot: 7,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '26C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '26C',
            value_raw: '0x1002e00',
          },
          {
            slot: 8,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '26C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '26C',
            value_raw: '0x11002e00',
          },
          {
            slot: 9,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '27C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '27C',
            value_raw: '0x11002f00',
          },
          {
            slot: 10,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '27C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '27C',
            value_raw: '0x11002f00',
          },
          {
            slot: 11,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '27C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '27C',
            value_raw: '0x1002f00',
          },
          {
            slot: 12,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '27C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '27C',
            value_raw: '0x1002f00',
          },
          {
            slot: 13,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '27C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '27C',
            value_raw: '0x1002f00',
          },
          {
            slot: 14,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '27C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '27C',
            value_raw: '0x1002f00',
          },
          {
            slot: 15,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '31C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '31C',
            value_raw: '0x1003300',
          },
          {
            slot: 16,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '32C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '32C',
            value_raw: '0x1003400',
          },
          {
            slot: 17,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '32C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '32C',
            value_raw: '0x1003400',
          },
          {
            slot: 18,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '33C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '33C',
            value_raw: '0x1003500',
          },
          {
            slot: 19,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '32C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '32C',
            value_raw: '0x1003400',
          },
          {
            slot: 20,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '32C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '32C',
            value_raw: '0x1003400',
          },
          {
            slot: 21,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '32C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '32C',
            value_raw: '0x1003400',
          },
          {
            slot: 22,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '32C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '32C',
            value_raw: '0x1003400',
          },
          {
            slot: 23,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '33C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '33C',
            value_raw: '0x1003500',
          },
          {
            slot: 24,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '33C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '33C',
            value_raw: '0x1003500',
          },
          {
            slot: 25,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '33C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '33C',
            value_raw: '0x1003500',
          },
          {
            slot: 26,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '33C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '33C',
            value_raw: '0x1003500',
          },
          {
            slot: 27,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '33C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '33C',
            value_raw: '0x1003500',
          },
          {
            slot: 28,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '32C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '32C',
            value_raw: '0x1003400',
          },
          {
            slot: 29,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '35C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '35C',
            value_raw: '0x1003700',
          },
          {
            slot: 30,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '37C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '37C',
            value_raw: '0x1003900',
          },
          {
            slot: 31,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '38C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '38C',
            value_raw: '0x1003a00',
          },
          {
            slot: 32,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '38C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '38C',
            value_raw: '0x1003a00',
          },
          {
            slot: 33,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '38C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '38C',
            value_raw: '0x1003a00',
          },
          {
            slot: 34,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '38C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '38C',
            value_raw: '0x1003a00',
          },
          {
            slot: 35,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '36C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '36C',
            value_raw: '0x1003800',
          },
          {
            slot: 36,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '36C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '36C',
            value_raw: '0x1003800',
          },
          {
            slot: 37,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '38C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '38C',
            value_raw: '0x1003a00',
          },
          {
            slot: 38,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '39C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '39C',
            value_raw: '0x1003b00',
          },
          {
            slot: 39,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '39C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '39C',
            value_raw: '0x1003b00',
          },
          {
            slot: 40,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '39C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '39C',
            value_raw: '0x1003b00',
          },
          {
            slot: 41,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '38C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '38C',
            value_raw: '0x1003a00',
          },
          {
            slot: 42,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '36C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '36C',
            value_raw: '0x1003800',
          },
          {
            slot: 43,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '37C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '37C',
            value_raw: '0x1003900',
          },
          {
            slot: 44,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '41C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '41C',
            value_raw: '0x1003d00',
          },
          {
            slot: 45,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '43C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '43C',
            value_raw: '0x1003f00',
          },
          {
            slot: 46,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '43C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '43C',
            value_raw: '0x1003f00',
          },
          {
            slot: 47,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '43C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '43C',
            value_raw: '0x1003f00',
          },
          {
            slot: 48,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '41C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '41C',
            value_raw: '0x1003d00',
          },
          {
            slot: 49,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '41C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '41C',
            value_raw: '0x1003d00',
          },
          {
            slot: 50,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '44C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '44C',
            value_raw: '0x1004000',
          },
          {
            slot: 51,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '44C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '44C',
            value_raw: '0x1004000',
          },
          {
            slot: 52,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '44C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '44C',
            value_raw: '0x1004000',
          },
          {
            slot: 53,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '42C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '42C',
            value_raw: '0x1003e00',
          },
          {
            slot: 54,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '38C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '38C',
            value_raw: '0x1003a00',
          },
          {
            slot: 55,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '39C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '39C',
            value_raw: '0x1003b00',
          },
          {
            slot: 56,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '39C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '39C',
            value_raw: '0x1003b00',
          },
          {
            slot: 57,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '39C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '39C',
            value_raw: '0x1003b00',
          },
          {
            slot: 58,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '38C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '38C',
            value_raw: '0x1003a00',
          },
          {
            slot: 59,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '37C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '37C',
            value_raw: '0x1003900',
          },
          {
            slot: 60,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '34C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '34C',
            value_raw: '0x1003600',
          },
          {
            slot: 61,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '33C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '33C',
            value_raw: '0x1003500',
          },
          {
            slot: 62,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '36C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '36C',
            value_raw: '0x1003800',
          },
          {
            slot: 63,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '38C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '38C',
            value_raw: '0x1003a00',
          },
          {
            slot: 64,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '39C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '39C',
            value_raw: '0x1003b00',
          },
          {
            slot: 65,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '40C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '40C',
            value_raw: '0x1003c00',
          },
          {
            slot: 66,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '40C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '40C',
            value_raw: '0x1003c00',
          },
          {
            slot: 67,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '43C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '43C',
            value_raw: '0x1003f00',
          },
          {
            slot: 68,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '45C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '45C',
            value_raw: '0x1004100',
          },
          {
            slot: 69,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '45C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '45C',
            value_raw: '0x1004100',
          },
          {
            slot: 70,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '46C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '46C',
            value_raw: '0x1004200',
          },
          {
            slot: 71,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '44C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '44C',
            value_raw: '0x1004000',
          },
          {
            slot: 72,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '42C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '42C',
            value_raw: '0x1003e00',
          },
          {
            slot: 73,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '40C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '40C',
            value_raw: '0x1003c00',
          },
          {
            slot: 74,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '43C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '43C',
            value_raw: '0x1003f00',
          },
          {
            slot: 75,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '45C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '45C',
            value_raw: '0x1004100',
          },
          {
            slot: 76,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '45C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '45C',
            value_raw: '0x1004100',
          },
          {
            slot: 77,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '45C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '45C',
            value_raw: '0x1004100',
          },
          {
            slot: 78,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '44C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '44C',
            value_raw: '0x1004000',
          },
          {
            slot: 79,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '45C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '45C',
            value_raw: '0x1004100',
          },
          {
            slot: 80,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '49C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '49C',
            value_raw: '0x1004500',
          },
          {
            slot: 81,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '49C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '49C',
            value_raw: '0x1004500',
          },
          {
            slot: 82,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '50C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '50C',
            value_raw: '0x1004600',
          },
          {
            slot: 83,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '50C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '50C',
            value_raw: '0x1004600',
          },
          {
            slot: 84,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '48C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '48C',
            value_raw: '0x1004400',
          },
          {
            slot: 85,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '45C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '45C',
            value_raw: '0x1004100',
          },
          {
            slot: 86,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '49C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '49C',
            value_raw: '0x1004500',
          },
          {
            slot: 87,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '49C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '49C',
            value_raw: '0x1004500',
          },
          {
            slot: 88,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '50C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '50C',
            value_raw: '0x1004600',
          },
          {
            slot: 89,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '49C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '49C',
            value_raw: '0x1004500',
          },
          {
            slot: 90,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '46C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '46C',
            value_raw: '0x1004200',
          },
          {
            slot: 91,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '44C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '44C',
            value_raw: '0x1004000',
          },
          {
            slot: 92,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '48C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '48C',
            value_raw: '0x1004400',
          },
          {
            slot: 93,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '49C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '49C',
            value_raw: '0x1004500',
          },
          {
            slot: 94,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '50C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '50C',
            value_raw: '0x1004600',
          },
          {
            slot: 95,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '50C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '50C',
            value_raw: '0x1004600',
          },
          {
            slot: 96,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '48C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '48C',
            value_raw: '0x1004400',
          },
          {
            slot: 97,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '46C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '46C',
            value_raw: '0x1004200',
          },
          {
            slot: 98,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '49C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '49C',
            value_raw: '0x1004500',
          },
          {
            slot: 99,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '50C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '50C',
            value_raw: '0x1004600',
          },
          {
            slot: 100,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '49C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '49C',
            value_raw: '0x1004500',
          },
          {
            slot: 101,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '48C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '48C',
            value_raw: '0x1004400',
          },
          {
            slot: 102,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '45C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '45C',
            value_raw: '0x1004100',
          },
          {
            slot: 103,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '49C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '49C',
            value_raw: '0x1004500',
          },
          {
            slot: 104,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '53C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '53C',
            value_raw: '0x1004900',
          },
          {
            slot: 105,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '29C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '29C',
            value_raw: '0x1003100',
          },
          {
            slot: 106,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '31C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '31C',
            value_raw: '0x1003300',
          },
          {
            slot: 107,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '17C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '17C',
            value_raw: '0x1002500',
          },
          {
            slot: 108,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '17C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '17C',
            value_raw: '0x1002500',
          },
          {
            slot: 109,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '57C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '57C',
            value_raw: '0x1004d00',
          },
          {
            slot: 110,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '75C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '75C',
            value_raw: '0x1005f00',
          },
          {
            slot: 111,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '62C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '62C',
            value_raw: '0x1005200',
          },
          {
            slot: 112,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '46C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '46C',
            value_raw: '0x1004200',
          },
          {
            slot: 113,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '46C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '46C',
            value_raw: '0x1004200',
          },
          {
            slot: 114,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '40C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '40C',
            value_raw: '0x1003c00',
          },
          {
            slot: 115,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '58C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '58C',
            value_raw: '0x1004e00',
          },
          {
            slot: 116,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '81C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '81C',
            value_raw: '0x1006500',
          },
          {
            slot: 117,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '68C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '68C',
            value_raw: '0x1005800',
          },
          {
            slot: 118,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '50C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '50C',
            value_raw: '0x1004600',
          },
          {
            slot: 119,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '51C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '51C',
            value_raw: '0x1004700',
          },
          {
            slot: 120,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '42C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '42C',
            value_raw: '0x1003e00',
          },
          {
            slot: 121,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '53C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '53C',
            value_raw: '0x1004900',
          },
          {
            slot: 122,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '47C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '47C',
            value_raw: '0x1004300',
          },
          {
            slot: 123,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '37C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '37C',
            value_raw: '0x1003900',
          },
          {
            slot: 124,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '48C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '48C',
            value_raw: '0x1004400',
          },
          {
            slot: 125,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '42C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '42C',
            value_raw: '0x1003e00',
          },
          {
            slot: 126,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '35C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '35C',
            value_raw: '0x1003700',
          },
          {
            slot: 127,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '48C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '48C',
            value_raw: '0x1004400',
          },
          {
            slot: 128,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '43C',
            },
            name: 'Temperature Sensor',
            descriptor: '',
            status: 'OK',
            value: '43C',
            value_raw: '0x1003f00',
          },
        ],
        has_slot_status: false,
      },
      {
        name: 'Voltage Sensor',
        descriptor: '',
        header: [
          'Descriptor',
          'Status',
          'Value',
        ],
        elements: [
          {
            slot: 1,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '201.0V',
            },
            name: 'Voltage Sensor',
            descriptor: '',
            status: 'OK',
            value: '201.0V',
            value_raw: '0x1004e84',
          },
          {
            slot: 2,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '12.29V',
            },
            name: 'Voltage Sensor',
            descriptor: '',
            status: 'OK',
            value: '12.29V',
            value_raw: '0x10004cd',
          },
          {
            slot: 3,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '203.0V',
            },
            name: 'Voltage Sensor',
            descriptor: '',
            status: 'OK',
            value: '203.0V',
            value_raw: '0x1004f4c',
          },
          {
            slot: 4,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '12.23V',
            },
            name: 'Voltage Sensor',
            descriptor: '',
            status: 'OK',
            value: '12.23V',
            value_raw: '0x10004c7',
          },
          {
            slot: 5,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '5.03V',
            },
            name: 'Voltage Sensor',
            descriptor: '',
            status: 'OK',
            value: '5.03V',
            value_raw: '0x10001f7',
          },
          {
            slot: 6,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '12.0V',
            },
            name: 'Voltage Sensor',
            descriptor: '',
            status: 'OK',
            value: '12.0V',
            value_raw: '0x10004b0',
          },
          {
            slot: 7,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '5.03V',
            },
            name: 'Voltage Sensor',
            descriptor: '',
            status: 'OK',
            value: '5.03V',
            value_raw: '0x10001f7',
          },
          {
            slot: 8,
            data: {
              Descriptor: '',
              Status: 'OK',
              Value: '12.0V',
            },
            name: 'Voltage Sensor',
            descriptor: '',
            status: 'OK',
            value: '12.0V',
            value_raw: '0x10004b0',
          },
        ],
        has_slot_status: false,
      },
    ],
    number: 0,
    label: 'HGST H4060-J 3010',
  } as Enclosure;

  constructor(number: number) {
    super(number);
    this.enclosureNumber = number;
    this.enclosureInit();
  }
}
