<h1 mat-dialog-title>{{ (title | translate) || ('Loading' | translate) }}</h1>
<mat-dialog-content class="job-dialog">
  <div *ngIf="showRealtimeLogs && realtimeLogs" class="logs-container">
    <h5>{{ 'Logs' | translate }}</h5>
    <pre #logs class="message logs">{{ realtimeLogs }}</pre>
  </div>

  <div *ngIf="isJobRunning">
    <div
      fxLayout="row"
      fxLayoutAlign="space-between baseline"
      fxLayoutGap="8px"
    >
      <span>{{ job.description ? job.description : job.method }}</span>
      <small *ngIf="!hideProgressValue">
        {{ progressTotalPercent || 0 | number: '1.2-2' }}%
      </small>
    </div>

    <mat-progress-bar
      color="primary"
      class="job-progress job-running"
      [value]="progressTotalPercent"
      [mode]="progressTotalPercent ? 'determinate' : 'indeterminate'"
    ></mat-progress-bar>
  </div>

  <div *ngIf="!isJobStateDeterminate">
    <mat-progress-bar
      color="primary"
      class="job-progress job-ended"
      [mode]="'indeterminate'"
    ></mat-progress-bar>
  </div>

  <div
    *ngIf="description"
    class="job-description"
    [innerHTML]="description"
  ></div>

  <div *ngIf="!description && !job">
    <mat-progress-bar
      color="primary"
      mode="indeterminate"
      class="job-progress"
    >
    </mat-progress-bar>
  </div>

</mat-dialog-content>

<div mat-dialog-actions>
  <ng-container
    *ngIf="job?.state === JobState.Failed; then buttonsFailed; else buttonsDefault"
  ></ng-container>

  <ng-template #buttonsFailed>
    <button
      class="mat-dialog-close"
      mat-icon-button
      mat-dialog-close="close"
      ixTest="close"
    >
      <ix-icon name="close"></ix-icon>
    </button>
  </ng-template>

  <ng-template #buttonsDefault>
    <button
      *ngIf="showAbortButton && job?.state === JobState.Running"
      mat-button
      ixTest="abort"
      (click)="abortJob()"
    >
      {{ 'Abort' | translate }}
    </button>

    <button
      *ngIf="showCloseButton"
      class="mat-dialog-close"
      mat-icon-button
      mat-dialog-close="close"
      ixTest="close"
    >
      <ix-icon name="close"></ix-icon>
    </button>
  </ng-template>
</div>
